<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            height="string"
            v-loading="loading">
            <el-table-column align="center" type="index" width="50" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              width="100"
              label="姓名"></el-table-column>
            <el-table-column prop="department" align="center" label="部门"></el-table-column>
            <el-table-column prop="post" align="center" label="岗位"></el-table-column>
            <el-table-column
              prop="entryDate"
              sortable
              min-width="110"
              align="center"
              label="入职日期">
              <template slot-scope="scope">
                {{ scope.row.entryDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="positiveDate"
              sortable
              min-width="110"
              align="center"
              label="转正日期">
              <template slot-scope="scope">
                {{ scope.row.positiveDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="200">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Miantan_Bgc"
                  @click.stop="miantan(scope.row)"
                  >转正</el-button
                >
                <el-button type="text" size="small" class="text_Yanqi_Bgc" @click="yanqi(scope.row)"
                  >延期</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total">
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit width="600px" :options="mt_form" :isShow.sync="showEdit" v-show="showEdit"> </Edit>

    <EditDialog
      width="600px"
      :isShow.sync="showEditDialog"
      :loading.sync="editDialogLoading"
      @submit="submit">
      <div slot="title" class="title">延期转正</div>
      <div slot="content" class="content">
        <el-form label-width="7em" :model="form" ref="form">
          <el-form-item label="延期至" prop="deptName" ref="deptName">
            <el-date-picker
              value-format="timestamp"
              v-model="form.positiveDate"
              :editable="false"
              type="date"
              placeholder="请选择延期日期"
              @keyup.enter.native="submit"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      form: {}, //延期信息
      mt_form: {}, //面谈附件
      tableData: [],
      loading: false,
      showEditDialog: false,
      showEdit: false,
      editDialogLoading: false,
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    yanqi(row) {
      this.form.id = row.id
      this.showEditDialog = true
    },
    miantan(row) {
      this.mt_form = row
      this.showEdit = true
    },
    getData() {
      this.loading = true
      this.$api.user
        .getPositiveUserInfo()
        .then(res => {
          this.loading = false
          this.tableData = res.data.records
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    re() {
      this.showEdit = false
      this.getData()
    },
    submit() {
      this.editDialogLoading = true
      let obj = {
        interviewRecordAddRecords: [],
        userInfoId: this.form.id,
        positiveDate: this.form.positiveDate,
      }
      this.$api.user
        .savaUserMt(obj)
        .then(res => {
          this.editDialogLoading = false
          this.showEdit = false
          this.$message({
            message: res.data,
            type: 'success',
          })
        })
        .catch(err => {
          this.editDialogLoading = false
          this.showEdit = false
          console.log(err)
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
